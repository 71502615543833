.o-banner-with-content-overlay {
  position: relative;
  max-width: 1920px;

  @include media-breakpoint-up(lg) {
    min-height: 540px;
    display: flex;
    align-items: center;
    padding: 7rem 0;
  }

  &__img {
    display: block;
    width: 100%;

    @include media-breakpoint-up(lg) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__container {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $grey-100;

    @include media-breakpoint-up(lg) {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-top: 0;
      padding-bottom: 0;
      background-color: transparent;
    }
  }

  &__content {
    @include media-breakpoint-up(lg) {
      width: 100%;
      max-width: 600px;
      padding: 7rem;
      background-color: rgba($grey-100, 0.9);
    }
  }

  .content {

    &__inner {
      max-width: 391px;
      margin: 0 auto;
    }

    &__heading {
      margin-bottom: .5rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 4rem;
      }
    }

    &__description {
      font-size: 12px;
      line-height: 18px;
      color: $grey-400;

      @include media-breakpoint-up(lg) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    &__button {
      margin-top: 2rem;
      display: block;

      @include media-breakpoint-up(lg) {
        margin-top: 4rem;
      }
    }
  }
}