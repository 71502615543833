.a-contact-info {
  position: relative;
  padding-left: 1.5rem;
  font-size: 12px;
  line-height: 1.5;
  color: $grey-400;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: $secondary;
  }

  p {
    margin-bottom: 0;
  }
}