.a-social-media-links {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  margin: 0 -1rem;

  &__item {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__item-link {
    display: block;
    padding: 1rem;
    line-height: 0;

    &:hover .a-icon {
      color: $black;
    }
  }
}