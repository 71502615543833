.a-video {
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
  }

  &:after {
    content: '';
    display: none;
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($black, 0.15);
  }

  &__icon {
    z-index: 3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    transition: opacity $transition-base;
  }

  &__img {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  iframe {
    pointer-events: none;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity $transition-base;
  }

  &.is-active {
    .a-video {
      &:after {
        display: none;
      }

      &__icon {
        pointer-events: none;
        opacity: 0;
      }

      &__img {

      }

    }

    iframe {
      pointer-events: all;
      opacity: 1;
    }
  }
}