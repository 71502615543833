.a-input-range {

  &__top {
    display: flex;
    align-items: center;
  }

  &__label {
    font-size: 14px;
    color: $black;
    margin-right: 2rem;
    line-height: 1;
  }

  &__slider {
    width: calc(100% - 56px);
    height: 3px;
    background: $grey-300;
    border-radius: 4px;
    border: none;
    box-shadow: none;

    .noUi-handle {
      $width: 15px;
      $rightPosition: ($width / 2) * -1;
      width: $width;
      height: $width;
      border-radius: 50%;
      background-color: $secondary;
      box-shadow: none;
      border: none;
      right: $rightPosition;
      outline: none;

      &::before,
      &::after {
        content: none;
      }

      &::before {
        content: '';
        width: 5px;
        height: 5px;
        background-color: $white;
        border-radius: 50%;
        position: absolute;
        left: 5px;
        top: 5px;
      }
    }

    .noUi-connect {
      background-color: $secondary;
    }
  }

  &__inputs {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
  }

  &__input-item {
    width: calc(50% - 1rem);
  }
}