.a-input-radio {
  input {
    display: none;
  }

  &__label {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;

    &:before {
      content: '';
      flex: 0 0 auto;
      display: block;
      width: 20px;
      height: 20px;
      border: solid 1px $grey-400;
      border-radius: 50%;
      margin-right: 1rem;
      box-sizing: border-box;
      transition: border $transition-base, background-color $transition-base;
    }
  }

  input:checked + &__label {
    &:before {
      border-color: $secondary;
      border-width: 6px;
      background: $white;
    }
  }
}