.m-hero-slider {
  position: relative;
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;

  @include media-breakpoint-up(lg) {
    padding-bottom: 12rem;
  }

  &__swiper {
  }

  .swiper {
    &-container, &-wrapper {
      max-height: 100%;
    }
  }

  &__slide {
    max-height: 100%;
    @include media-breakpoint-up(lg) {
      max-height: 70vh;
    }
  }

  .slide {
    &__inner {
      max-height: 100%;
      overflow: hidden;
      @include media-breakpoint-up(md) {
        max-height: calc(100vh - 16rem);
      }

      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 80%;
        max-height: 100%;

        @include media-breakpoint-up(md) {
          padding-bottom: 42%;
        }
      }
    }

    &__content {
      z-index: 20;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 25%;
      left: 50%;
      width: 100%;
      padding: 0 2rem;
      transform: translate(-50%, -50%);

      @include media-breakpoint-up(xs) {
        padding: 0;
      }

      @include media-breakpoint-up(md) {
        width: 80%;
      }
    }

    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
      text-align: center;
      color: $white;

      @include media-breakpoint-up(md) {
        display:block;
        width:100%;    
        padding: 12rem;
      }
    }

    &__subtitle {
      padding: 0 1rem 1rem;
      margin: 0;
      font-size: 12px;


      @include media-breakpoint-up(xs) {
        font-size: 13px;
      }

      @include media-breakpoint-up(sm) {
        font-size: 14px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 20px;
        padding-bottom: 2rem;
      }
    }

    &__title {
      height: 24px;
      margin-bottom: 0;
      font-size: 24px;
      line-height: 1.2;
      letter-spacing: 0.02em;
      font-family: $font-family-headings;
      text-transform: uppercase;

      @include media-breakpoint-up(xs) {
        height: 32px;
        font-size: 32px;
      }

      @include media-breakpoint-up(lg) {
        height: 64px;
        font-size: 64px;
      }
    }

    &__cta {
      display: inline-flex;
      padding: 1.5rem 1rem 1rem;
      margin: 0;
      align-items: center;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1.5;
      font-weight: $font-weight-medium;
      color: inherit;

      @include media-breakpoint-up(xs) {
        font-size: 13px;
      }

      @include media-breakpoint-up(sm) {
        font-size: 14px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 2.5rem;
      }

      &:after {
        content: '';
        display: inline-block;
        width: 6px;
        height: 11px;
        margin-left: 1rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: currentColor;
        mask-image: url('../../../../../assets/private/img/chevron.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        transition: margin $transition-base;
      }
    }

    &__img {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding: 0;
      object-fit: cover;
    }
  }

  &__pagination {
    display: none;
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(lg) {
      position: absolute;
      left: 50%;
      bottom: 8px;
      transform: translateX(-50%);
      width: 100%;
      max-width: 1650px;
      display: flex;
      align-items: flex-end;
      z-index: 10;
    }

    &.pagination--mobile {
      display: none;
    }
  }

  .pagination {
    &__item {
      position: relative;
      flex: 1 1 auto;
      overflow: hidden;
      margin-bottom: 2rem;
      background: $grey-100;
      box-shadow: 0px 2px 4px rgba(213, 213, 213, 0.5);
      border-radius: 4px;
      text-align: center;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        width: 33.33%;
        margin: 0 1.5rem;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1rem;
        background: $secondary;
        transform: translateY(100%);
        transition: transform $transition-base;
      }

      &-inner {
        max-width: 320px;
        display: inline-flex;
        align-items: center;
        height: 10rem;
        padding: 1.5rem 2.5rem;
        text-align: left;
        color: $grey-400;
        transition: height $transition-base;

        @include media-breakpoint-up(lg) {
          width: 100%;
          max-width: unset;
        }
      }

      &-img {
        display: block;
        width: 104px;
        margin-right: 16px;
        border-radius: 4px;
        overflow: hidden;
      }

      &-content {
        width: calc(100% - 120px);
      }

      &-title, &-subtitle {
        display: block;
        width: 100%;
        min-width:160px;
      } 
 
      &-title {
        font-size: 16px;
        line-height: 1.1;
        font-weight: $font-weight-medium;
        transition: color $transition-base;
      }

      &-subtitle {
        font-size: 14px;
        line-height: 1.1;
        margin-top: .5rem;
      }

      &.is-active {
        .pagination {
          &__item {
            &-inner {
              height: 116px;
            }
          }
        }

        &:after {
          transform: translateY(0);
        }
      }
    }

    &--mobile .pagination {
      &__item {
        &-subtitle {
          color: $secondary;
        }
      }
    }
  }

  //  TODO: Style this button after changing .a-slider-button
  &__button {
    z-index: 30;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &--prev {
      left: 2rem;
    }

    &--next {
      right: 2rem;
    }
  }

  &--service {
    .m-hero-slider {
      &__swiper {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
        }
      }

      &__pagination {
        &.pagination--desktop {
          display: none;

          @include media-breakpoint-up(lg) {
            display: flex;
          }
        }

        &.pagination--mobile {
          display: block;

          .pagination {
            &__item {

            }
          }

          @include media-breakpoint-up(lg) {
            display: none;
          }
        }
      }

      .pagination {
      }
    }
  }
}