.a-spinner {
  width: 6rem;
  height: 6rem;
  line-height: 0;

  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: solid .5rem transparent;
    border-radius: 50%;
    border-left-color: $black;
    border-right-color: $black;

    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}