.a-status {
  display: flex;
  background: rgba($green, 0.1);
  color: $green;
  padding: 2rem 3rem;
  line-height: 0;

  &__icon {
    flex: 0 0 auto;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-top: 1px;
    margin-right: 0.75rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    flex: 1 1 auto;
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
  }

  &--warning {
    background: rgba($orange, 0.1);
    color: $orange;
  }

  &--error {
    background: rgba($red-error, 0.1);
    color: $red-error;
  }
}