.a-pagination {
  width: 100%;
  margin: 3rem 0 4rem;

  @include media-breakpoint-up(lg) {
    margin: 3rem 0 1rem;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  &__button {
    display: block;
    border: none;
    background-color: transparent;
    padding: .5rem;

    &--hidden {
      visibility: hidden;
    }

    &--prev {
      @include media-breakpoint-up(lg) {
        padding-right: 1.5rem;
      }
    }

    &--next {
      @include media-breakpoint-up(lg) {
        padding-left: 1.5rem;
      }
    }

    &--active {
      pointer-events: none;

      &.button {
        .button {
          &__number-wrapper {
            background-color: $black;
          }

          &__number {
            color: $white;
          }
        }
      }
    }
  }

  .button {
    &__text {
      display: none;
      font-size: 12px;
      color: $secondary;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &__arrow-wrapper {
      display: block;
      position: relative;
      width: 28px;
      height: 28px;
      background-color: $primary;
      border-radius: 50%;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &__arrow {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &__number-wrapper {
      display: block;
      position: relative;
      width: 28px;
      height: 28px;
      background-color: $grey-200;
      border-radius: 50%;
    }

    &__number {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $grey-400;
      font-size: 12px;
      line-height: 1;
    }
  }
}