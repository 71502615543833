.a-section-heading {
  text-align: center;
  max-width: 1016px;
  margin-left: auto;
  margin-right: auto;

  &__black {
    color: $black;
  }

  &__grey {
    color: $grey-400;
  }

  strong {
    color: $black;
  }
}