.a-input-date {
  position: relative;
  overflow: hidden;
  height: 44px;
  width: 100%;
  border: solid 1px $grey-300;
  border-radius: $border-radius;

  &:before, &__input::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 50%;
    left: 2rem;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    transform: translateY(-50%);
  }

  &:before {
    content: '';
    mask-image: url('../../../../../assets/private/img/volvo-icons/icon_calendar_2.svg');
    background-color: currentColor;
    background-image: none;
    pointer-events: none;
  }

  &__input {
    height: 100%;
    min-width: 100%;
    padding-left: 6rem;
    border: none;
    margin: 0;

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      opacity: 0;
      top: 50%;
      left: 2rem;
      width: 24px;
      height: 24px;
      padding: 0;
      margin: 0;
      transform: translateY(-50%);
    }

    &:focus, &:focus-visible {
      outline: 0;
    }
  }
}