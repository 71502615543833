.o-text-editor-extended {

  &__content {
    width: 100%;
    max-width: 808px;
    margin: 0 auto;

    h2 {
      font-size: 16px;
      line-height: 24px;
      color: $black;
      font-weight: $font-weight-medium;
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 1rem;
      }
    }

    p {
      font-size: 12px;
      line-height: 18px;
      color: $black;
      margin-bottom: 3rem;

      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 4rem;
      }
    }

    a {
      transition: color $transition-base;
    }

    ul {
      list-style-type: none;
      margin-bottom: 3rem;
      padding-left: 2rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 4rem;
      }

      li {
        font-size: 12px;
        line-height: 18px;
        color: $black;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
          font-size: 14px;
          line-height: 24px;
        }

        &::before {
          content: "\2022";
          color: $secondary;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }
}