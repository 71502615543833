.a-hamburger {
  background-color: transparent;
  border: 0;
  padding: 0;

  &__text {
    display: none;
    font-size: 14px;
    color: $black;
    font-weight: 500;
    margin-right: 1rem;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__lines {
    position: relative;
    display: block;
    width: 23px;
    height: 17px;

    &:before, &:after {
      content: '';
      transition: all $transition-base;
    }

    &-inner, &:before, &:after {
      position: absolute;
      right: 0;
      height: 1px;
      border-radius: 2px;
      background-color: $black;
    }

    &-inner {
      top: 8px;
      width: 18px;
      transition: opacity $transition-base;
    }

    &:before {
      top: 0;
      width: 100%;
    }

    &:after {
      bottom: 0;
      width: 20px;
    }
  }

  &.is-active {
    .a-hamburger {
      &__lines {

        &-inner {
          opacity: 0;
        }

        &::before,
        &::after {
          width: 100%;
        }

        &::before {
          top: 8px;
          transform: rotate(-45deg);
        }

        &::after {
          bottom: 8px;
          transform: rotate(45deg);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
  }
}