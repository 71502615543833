.l-contact {

  &__header {
  }

  &__container {
    @include media-breakpoint-up(lg) {
      padding-right: 0; //  padding is on .o-form element
    }
  }

  &__wrapper {
    overflow-x: hidden;
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  &__main {
    flex: 1 1 auto;
  }

  .main {
    &__employees {
    }

    .employees {
      &__heading {
        margin-bottom: 2rem;

        @include media-breakpoint-up(lg) {
          padding-bottom: 2rem;
          border-bottom: 1px solid $grey-300;
          margin-bottom: 2.5rem;
        }
      }

      &__form {
        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: flex-end;
          margin-bottom: 4rem;
        }
      }

      .form {
        &__input {
          margin-bottom: 2rem;

          @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            width: 100%;
            max-width: 392px;
          }
        }

        &__showroom-filter {
          margin-bottom: 1rem;

          @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            margin-left: 3rem;
          }
        }
      }

      &__showroom {
        display: none;

        &.is-active {
          display: block;
        }
      }

      &__not-found {
        display: none;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        color: $grey-400;
        margin-bottom: 6rem;

        &.is-active {
          display: block;
        }
      }

      &__category {
        display: none;
        margin-bottom: 5rem;


        @include media-breakpoint-up(lg) {
          margin-bottom: 6rem;
        }

        &.is-active {
          display: block;
        }
      }

      .category {
        &__heading {
          font-size: 14px;
          line-height: 24px;
          color: $black;
          text-transform: uppercase;
          margin-bottom: 1.5rem;

          @include media-breakpoint-up(lg) {
            margin-bottom: 2rem;
          }
        }

        &__list {
          list-style-type: none;
          padding: 0;
          margin: 0;

          @include media-breakpoint-up(lg) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            max-width: 675px;
          }
        }

        &__list-item {
          display: none;
          width: 100%;
          margin-bottom: 3rem;

          &:last-child {
            margin-bottom: 0;
          }

          @include media-breakpoint-up(lg) {
            width: 50%;
            padding-right: 4rem;
          }

          &.is-active {
            display: flex;
          }
        }
      }
    }

    &__showroom-info {
      text-align: right;
      margin-bottom: 4rem;
    }

    .showroom-info {
      &__inner {
        text-align: left;

        @include media-breakpoint-up(sm) {
          display: flex;
          flex-direction: row-reverse;
        }
      }

      &__map {
        background: #8cd3e5;
        position: relative;

        @include media-breakpoint-up(sm) {
          width: 50%;
        }

        &:before {
          content: '';
          display: block;
          padding-bottom: 100%;

          @include media-breakpoint-up(sm) {
            display: none;
          }
        }

        &-inner {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }

      &__content {
        padding: 5rem 2rem;
        border-left: solid 1rem $primary;
        background: $grey-100;
        font-size: 14px;
        line-height: 24px;
        color: $grey-400;

        @include media-breakpoint-up(sm) {
          width: 50%;
        }

        p {
          margin: 0;
        }
      }

      .content {
        &__title {
          width: 100%;
          padding: 0 1rem;
          margin: 0;
          font-size: 20px;
          line-height: 28px;
          color: $black;
        }

        &__subtitle {
          margin-bottom: 0;
          font-size: inherit;
          line-height: inherit;
          font-weight: $font-weight-medium;
          color: $black;
        }

        &__contact {
          flex-basis: 33.33%;
          padding: 0 1rem;
        }

        &__opening-hours {
          flex-basis: 50%;
          padding: 0 1rem;
          margin-top: 2rem;
        }

        &__additional-info {
          &:before {
            content: '';
            display: inline-block;
            float: left;
            width: 14px;
            height: 14px;
            margin: 6px 1rem 0 0;
            background-color: currentColor;
            mask-image: url('../../../../assets/private/img/warningIcon.svg');
            mask-size: contain;
            mask-repeat: no-repeat;
          }
        }
      }

      &__see-employees {
        display: inline-block;
        padding: 2rem;
        margin: 0 -2rem;
        font-size: 14px;
        font-weight: $font-weight-medium;
        line-height: 24px;
        color: $secondary;
        text-transform: uppercase;
        transition: color $transition-base;
        border: 0;
        background-color: transparent;

        @include media-breakpoint-up(lg) {
          margin-right: 2rem;
        }

        &:hover {
          color: $primary;
        }
      }

      &--has-no-map {
        .showroom-info {
          &__inner {
            @include media-breakpoint-up(sm) {
              display: block;
            }
          }

          &__content {
            @include media-breakpoint-up(sm) {
              width: 100%;
            }

            .content {

              &__columns {
                @include media-breakpoint-up(md) {
                  display: flex;
                  flex-wrap: wrap;
                }
              }

              &__column {
                @include media-breakpoint-up(md) {
                  flex-basis: 33.3%;
                  &--opening-hours {
                    display: flex;
                    flex-wrap: wrap;
                    flex-basis: 66.6%;
                  }
                }
              }

              &__title {
                @include media-breakpoint-up(md) {
                  width: 33.3%;
                }
              }

              &__opening-hours {
                @include media-breakpoint-only(md) {
                  margin-top: 0;
                }

                @include media-breakpoint-only(xl) {
                  margin-top: 0;
                }

                &:nth-child(n+3) {
                  @include media-breakpoint-up(md) {
                    margin-top: 3rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__aside {
    position: relative;
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: 100%;
      max-width: 440px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 25px 35px rgba(0, 0, 0, 0.03);
    }

    &.is-sticky-over .aside__form {
      position: absolute;
      top: 0;
      width: 100%;
    }

    &.is-sticky .aside__form {
      position: fixed;
      top: $header-height;
      width: auto;

      body.admin-bar & {
        top: calc(#{$header-height} + 32px)
      }
    }

    &.is-sticky-under .aside__form {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  .aside {
    &__form {
      z-index: 1100;
      position: fixed;
      top: 0;
      right: 0;
      background: $white;
      width: 100%;
      height: 100%;
      max-width: 445px;
      transform: translate(100%);
      transition: transform $transition-base;

      @include media-breakpoint-up(lg) {
        z-index: 10;
        width: 100%;
        max-height: calc(100vh - #{$header-height});
        transform: translate(0);
      }

      body.admin-bar & {
        @include media-breakpoint-only(md) {
          top: 32px;
        }

        @include media-breakpoint-up(lg) {
          top: auto;
          max-height: calc(100vh - #{$header-height} - 32px);
        }
      }

      &.o-form {
        &__form {

        }

        .form {
          //&__header,
          &__content,
          &__footer {
            @include media-breakpoint-up(lg) {
              padding-right: $grid-container-gutter-width-desktop/2;
            }
          }

          &__header {
            @include media-breakpoint-up(lg) {
              display: none;
            }
          }
        }

        .thanks {
          &__close {
            @include media-breakpoint-up(lg) {
              display: none;
            }
          }
        }
      }
    }

    &__bar {
      z-index: 900;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 1.5rem 2rem;
      background: $grey-100;
      box-shadow: 0 -4px 4px rgb(213 213 213 / 50%);
      text-align: center;

      @include media-breakpoint-up(lg) {
        display: none;
      }

      &-button {
        width: 100%;
        max-width: 288px;;
      }
    }

    &__backdrop {
      z-index: 1090;
      pointer-events: none;
      position: fixed;
      opacity: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $black;
      transition: opacity $transition-base;
    }
  }

  &.is-opened {
    .aside {
      &__form {
        transform: translate(0);
      }

      &__backdrop {
        pointer-events: all;
        opacity: 0.8;
      }
    }
  }
}