@import "~tippy.js/dist/tippy.css";

.tippy-tooltip {
  background-color: $black;
}

.tippy-content {
  font-size: 12px;
  line-height: 18px;
  color: $white;
}

.a-tooltip {
  display: inline-block;
  width: 12px;
  height: 12px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    mask-image: url('../../../../../assets/private/img/tooltip-icon.svg');
    mask-size: cover;
    background-color: $black;
  }

  @include media-breakpoint-up(lg) {
    width: 16px;
    height: 16px;
  }
}