.l-tyre-labels {
  &__list {
    list-style-type: none;
    padding: 0;
    margin: 5rem 0 0;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin: 3rem -1.5rem 0;
    }
  }

  &__item {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 2rem;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      margin: 0 1.5rem 3rem;
      width: calc((100% / 3) - 3rem);
    }

    .item {
      &__img {
        display: block;
        width: 100%;
        border-radius: 10px;
        image-rendering: -webkit-optimize-contrast;
      }
    }
  }
}