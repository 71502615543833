.a-checkbox {
  display: flex;
  width: 100%;
  font-size: 14px;
  line-height: 24px;

  input {
    display: none;
  }

  &__icon {
    position: relative;
    flex: 0 0 auto;
    display: inline-block;
    box-sizing: border-box;
    width: 2.5rem;
    height: 2.5rem;
    border: solid 1px $grey-400;
    border-radius: $border-radius;
    margin: 0.25rem 0.75rem 0 0;
    transition: background-color $transition-base;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      opacity: 0;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 10px;
      mask-image: url('../../../../../assets/private/img/checkmark.svg');
      background-color: $white;
      transform: translate(-50%, -50%);
      transition: opacity $transition-base;
    }
  }

  &__label {
    flex: 1 1 auto;

    &-main {
      display: inline;
      cursor: pointer;
    }

    &-details {
      display: inline;

      .details {
        &__text {
          display: block;
          overflow: hidden;
          max-height: 0;
          margin-top: 0;
          transition: max-height .5s $transition-function, margin .5s $transition-function;

          &-inner {
            display: block;
          }
        }

        &__trigger {
          color: $blue-light;
          cursor: pointer;
          white-space: nowrap;
          transition: color $transition-base;

          &.is-hidden {
            display: none;
          }

          &:hover {
            color: $blue;
          }
        }
      }

      &.is-expanded {
        .details {
          &__text {
            margin-top: 1rem;
          }
        }
      }
    }
  }

  &.is-active {
    .a-checkbox {
      &__icon {
        border-color: $blue-light;
        background-color: $blue-light;

        &:before {
          opacity: 1;
        }
      }
    }
  }

  &.has-error {
    .a-checkbox {
      &__icon {
        border-width: 2px;
        border-color: $red-error;
      }
    }
  }
}