@import "functions";

@import "mixins/breakpoints";
@import "mixins/grid";
@import "mixins/grid-framework";

@import "variables/variables";

@import "reboot";

@import "grid";
@import "typography";

@import "~swiper/swiper";
@import "~nouislider";

@import "../../../includes/views/components/components";
@import "../../../includes/views/layouts/layouts";

#wpadminbar {
  @media (max-width: 782px) {
    display: none;
  }
}

#wp-admin-bar-duplicate-post {
  display: none;
}