.a-select {
  position: relative;

  select {
    display: none;
  }

  &__input {
    z-index: 10;
    position: relative;
    cursor: pointer;

    &-arrow {
      position: absolute;
      top: 50%;
      right: 2rem;
      transform: translateY(-50%);
      transition: transform $transition-base;
    }

    .a-input {
      &__field {
        position: relative;
        overflow: hidden;
        white-space: nowrap;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
          width: 6rem;
          border-right: solid 4rem #fff
        }
      }
    }
  }

  &__list {
    z-index: 20;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .list {
    pointer-events: none;
    width: 100%;

    &__expandable {
      overflow: hidden;
      width: 100%;
      max-height: 0;
      border-radius: 0px 0px $border-radius $border-radius;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 35px rgba(0, 0, 0, 0.03);
      transition: max-height $transition-base;
    }

    &__items {
      pointer-events: all;
      overflow-y: auto;
      list-style: none;
      width: 100%;
      max-height: 200px;
      padding: 0 0 2rem 0;
      margin: 0;
      background: $white;
    }

    &__search {
      position: sticky;
      top: 0;
      width: 100%;
      padding: 1rem 2rem 0;
      font-size: 14px;
      line-height: 24px;
      background-color: $grey-100;
      z-index: 10;

      .search {
        &__wrapper {
          position: relative;
        }

        &__input {
          width: 100%;
          background: transparent;
          border: none;
          border-bottom: $grey-300 1px solid;
          padding-bottom: 1rem;
          padding-left: 3rem;

          &:focus {
            outline: none;
          }
        }

        &__icon {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    &__item {
      display: flex;
      width: 100%;
      padding: 1rem 2rem;
      font-size: 14px;
      line-height: 24px;
      cursor: pointer;

      &:hover {
        background-color: $grey-100;
      }

      &.is-active {
        .item {
          &__icon {
            border-color: $blue-light;
            background-color: $blue-light;
          }
        }
      }

      &.is-hidden {
        display: none;
      }
    }

    .item {
      &__icon {
        position: relative;
        flex: 0 0 auto;
        display: inline-block;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        border: solid 1px $grey-400;
        border-radius: $border-radius;
        margin: 0.25rem 0.75rem 0 0;
        background-color: $white;
        transition: background-color $transition-base;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 12px;
          height: 10px;
          background-image: url('../../../../../assets/private/img/checkmark.svg');
          transform: translate(-50%, -50%);
        }
      }

      &__label {
        flex: 1 1 auto;
      }
    }

  }

  &.has-error {
    .a-select {
      &__input {
        .a-input {
          &__field {
            border-width: 2px;
            border-color: $red-error;
          }

          &__label {
            color: $red-error;
          }
        }
      }
    }
  }

  &.is-expanded {
    .a-select {
      &__input-arrow {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}