.a-textarea {
  position: relative;
  line-height: 0;

  &__inner {
    overflow: hidden;
    padding-top: 2.5rem;
    border: solid 1px $grey-300;
    border-radius: $border-radius;
    background-color: $white;
  }

  &__field, &__label {
    display: inline-block;
  }

  &__field {
    width: 100%;
    height: 44px;
    padding: 0 2rem 1rem 2rem;
    border: 0;
    font-size: 14px;
    line-height: 1;

    &:focus {
      outline: 0;
    }
  }

  &__label {
    pointer-events: none;
    position: absolute;
    top: 15px;
    left: 2rem;
    margin: 0;
    font-size: 14px;
    line-height: 1;
    color: $grey-400;
    transition: top $transition-base, font-size $transition-base, transform $transition-base;
  }

  &.is-active {
    .a-textarea {
      &__label {
        top: 6px;
        font-size: 12px;
      }
    }
  }

  &.is-focused {
    .a-textarea {
      &__inner {
        border-color: $blue-light;
      }
    }
  }

  &.has-error {
    .a-textarea {
      &__inner {
        border-color: $red-error;
      }

      &__label {
        color: $red-error
      }
    }
  }
}